<template>
    <main class="the-dashboard-frame">
        <TheCookieConsent />

        <nav
            class="the-dashboard-frame__navbar navbar navbar-light navbar-expand-sm bd-highlight px-0"
        >
            <div class="the-dashboard-frame__navbar__body">
                <RouterLink
                    :to="{ name: ROUTE.DASHBOARD }"
                    class="navbar-brand bd-highlight mr-auto"
                >
                    <img src="@/assets/refair-me-logo.png" height="30" />
                </RouterLink>

                <button
                    class="navbar-toggler navbar-toggler-right rounded"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto mr-3">
                        <li class="nav-item mx-0 mx-lg-1">
                            <RouterLink
                                class="nav-link py-3 px-0 px-lg-3"
                                :to="{ name: ROUTE.JOB_SEARCH }"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                            >
                                JOB LIST
                            </RouterLink>
                        </li>
                    </ul>
                </div>

                <TheNavbarUser />
            </div>
        </nav>

        <div class="the-dashboard-frame__container">
            <aside class="the-dashboard-frame__container__sidebar">
                <div class="the-dashboard-frame__container__sidebar__body">
                    <div class="the-dashboard-frame__container__sidebar__body__content">
                        <TheSidebar />
                    </div>
                </div>
            </aside>

            <section class="the-dashboard-frame__container__body">
                <RouterView />
            </section>
        </div>
    </main>
</template>

<script>
import { TheNavbarUser, TheCookieConsent } from '@/components'
import { TheSidebar } from './components'
import { ROUTE } from '@/enums'

export default {
    name: 'TheDashboardFrame',

    components: { TheNavbarUser, TheSidebar, TheCookieConsent },

    data: () => ({ ROUTE }),
}
</script>

<style lang="sass" scoped>
.the-dashboard-frame
    display: flex
    flex-direction: column
    min-height: 100vh

    &__navbar
        width: 100%

        &__body
            display: flex
            width: 100%
            max-width: $base-content-max-width
            margin: 0 auto
            +padding-sm(left)
            +padding-sm(right)
            +on-sm-and-up
                +padding(left)
                +padding(right)

    &__container
        flex: 1 1 auto
        width: 100%
        max-width: $base-content-max-width
        margin: 0 auto
        display: flex
        +on-md-and-down
            flex-direction: column

        &__sidebar
            flex: 0 1 auto
            +on-md-and-down
                border-bottom: 1px dashed rgba(black, .1)
            +on-lg-and-up
                flex: 0 0 280px

            &__body
                +on-lg-and-up
                    position: sticky
                    top: 0
                    max-height: 100vh
                    overflow-y: auto

                &__content
                    +padding-sm
                    padding-right: 0 !important
                    +on-sm-and-up
                        +padding

        &__body
            flex: 1 1 auto
            +padding-sm
            +on-sm-and-up
                +padding
</style>
