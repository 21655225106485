











import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'

export default Vue.extend({
    name: 'SidebarGroup',

    props: {
        icon: {
            type: Object as () => IconDefinition,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
})
