







import Vue from 'vue'
import { Location } from 'vue-router'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'SidebarLink',

    props: {
        to: {
            type: Object as () => Location,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },

    data: () => ({ faAngleRight }),
})
