














































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import {
    faBriefcase,
    faUserFriends,
    faProjectDiagram,
    faAddressCard,
    faUserNinja,
    faUser,
} from '@fortawesome/free-solid-svg-icons'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { SidebarGroup, SidebarLink, BookConsultation } from './components'
import { STORE_MODULE, ROUTE } from '@/enums'
import { IStateAuth } from '@/types'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default Vue.extend({
    name: 'TheSidebar',

    components: { SidebarGroup, SidebarLink, BookConsultation },

    data: () => ({
        isCollapsedOnMobile: true,
        ROUTE,
        faBuilding,
        faBriefcase,
        faUserFriends,
        faProjectDiagram,
        faAddressCard,
        faUserNinja,
        faUser,
    }),

    computed: {
        ...(mapState(['userAccount']) as MapStateToComputed<IStateAuth>),

        isAdmin() {
            return this.userAccount?.userRole === 'admin'
        },

        isCandidate() {
            return this.userAccount?.userRole === 'candidate'
        },

        isCompanyAdmin() {
            return this.userAccount?.userRole === 'company_admin'
        },
    },
})
