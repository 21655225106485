

























import Vue from 'vue'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { BaseBtn, BaseCalendlyAssetLoader } from '@/components'

declare const Calendly: {
    initPopupWidget({ url }: { url: string }): void
}

const CALENDAR_URL = 'https://calendly.com/dermotc/30min'

export default Vue.extend({
    name: 'BookConsultation',

    components: { BaseBtn, BaseCalendlyAssetLoader },

    data: () => ({
        hasClickedButton: false,
        areAssetsLoaded: false,
        faCalendarAlt,
    }),

    computed: {
        isLoading(): boolean {
            return this.hasClickedButton && !this.areAssetsLoaded
        },
    },

    methods: {
        async loadAndShowWidget(): Promise<void> {
            this.hasClickedButton = true

            if (this.areAssetsLoaded) {
                this.showWidget()
            } else {
                const unwatch = this.$watch('areAssetsLoaded', () => {
                    this.showWidget()
                    unwatch()
                })
            }
        },

        showWidget(): void {
            Calendly.initPopupWidget({ url: CALENDAR_URL })
        },
    },
})
